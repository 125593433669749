<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
              max-height="auto"
              max-width="250px"
              alt="logo"
              contain
              class=""
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-light text--primary text-center mb-0">Recuperar Senha!</p>
          <p class="text-center mb-2">Para recuperar a sua senha insira o seu e-mail cadastrado no sistema.</p>
        </v-card-text>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              type="email"
              outlined
              label="E-mail"
              placeholder="nome@daher.adm.br"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-btn :to="{ name: 'change-password' }" block x-large color="primary" class="mt-6"> Enviar </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const email = ref('')

    return {
      email,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>